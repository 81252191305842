@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS for the glow animation */
@keyframes glowing-border {
  0% {
    box-shadow: 0 0 5px 2px rgba(220, 220, 220, 0.5);
  }
  25% {
    box-shadow: 0 0 10px 5px rgba(220, 220, 220, 0.7);
  }
  50% {
    box-shadow: 0 0 5px 2px rgba(220, 220, 220, 0.5);
  }
  75% {
    box-shadow: 0 0 10px 5px rgba(220, 220, 220, 0.7);
  }
  100% {
    box-shadow: 0 0 5px 2px rgba(220, 220, 220, 0.5);
  }
}

/* Tailwind CSS classes for the animation */
.animate-glow {
  animation: glowing-border 4s ease-in-out;
  /* Adjust the duration and other animation properties as needed */
}

@keyframes appear-letter {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: auto;
    opacity: 1;
  }
}

/* Apply the animation to the text */
.animate-appear-letter > * {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: appear-letter 0.5s ease-in-out both;
  animation-delay: calc(var(--animation-order) * 0.1s);
}

@layer base {
  html {
    font-family: 'Fira Sans', sans-serif;
    color: var(--tw-color-gray-900);
    background-color: var(--tw-color-white);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-firasans;
  }

  p, button {
    @apply font-lato;
  }

  a {
    @apply text-blue-500;
  }

}

.glass-button {
  padding: 16px;
  border: 0;
  text-decoration: none;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.1);
  backdrop-filter: blur(30px);
  color: rgba(255,255,255,0.8);
  font-size: 14px;
  letter-spacing: 2px;
  cursor: pointer;
  text-transform: uppercase;
}

.glass-button:hover {
  background-color: rgba(255,255,255,0.2);
}
/* Style for the button */
.dot-button {
  position: relative;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #1DB954;
  color: #1DB954;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
}

/* Pseudo-element for the animated dot */
.dot-button::before {
  content: "";
  position: absolute;
  top: -4px; /* Adjust the initial position of the dot to align with the border */
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: #1DB954; /* Color of the dot */
  border-radius: 50%;
  animation: move-dot 6s linear;
}

/* Keyframes animation for the dot movement */
@keyframes move-dot {
  0% {
    top: -4px;
    left: -4px;
  }
  25% {
    top: -4px;
    left: calc(100% - 4px);
  }
  50% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }
  75% {
    top: calc(100% - 4px);
    left: -4px;
  }
  100% {
    top: -4px;
    left: -4px;
  }
}

.glow-dot-button {
  position: relative;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #03e9f4;
  color: #03e9f4;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
}

/* Pseudo-element for the animated dot */
.glow-dot-button::before {
  content: "";
  position: absolute;
  top: -4px; /* Adjust the initial position of the dot to align with the border */
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: #f54020; /* Color of the dot */
  border-radius: 50%;
  animation: move-dot 2s linear infinite, glow-dot 2s linear infinite alternate;
  opacity: 0;
}
/* Keyframes animation for the dot glow */
@keyframes glow-dot {
    0% {
          transform: scale(1);
              opacity: 1;
                }
                  100% {
                        transform: scale(1.3);
                            opacity: 0.5;
                              }
                            }

